import { required } from 'vuelidate/lib/validators';
import { ModelSelect } from 'vue-search-select';
import MasterRules from '../../workFlowMasterRule';
import ApplicationCriteria from '../../applicationCriteria';
import store from '../../../../../store';
export default {
  name: 'addRule',
  components: {
    ModelSelect,
    MasterRules,
    ApplicationCriteria
  },
  data() {
    return {
      setTimeVsetCode: null,
      showValueSetModal: false,
      appCriteriaModal: false,
      mainAppCreriaModal: false,
      showMasterRuleModal: false,
      unsubscribe: null,
      ruleIndex: null,
      editMode: true,
      isSuccess: false,
      showAlert: false,
      respMessage: null,
      loader: false,
      legalEntityList: [],
      selectedLegalEntity: {
        value: null,
        text: null
      },
      ruleForm: {
        rule_hdr_id: null,
        rule_name: null,
        active: false,
        le_id: null,
        le_name: null,
        rule_details: [
          {
            master_rule_hdr_id: null,
            rule_mpng_id: null,
            master_rule_name: null
          }
        ]
      },
      ruleDetailFeilds: [
        {
          key: 'master_rule_name',
          label: 'Master Rule'
        },
        {
          key: 'application_criteria',
          class: 'd-none'
        },
        {
          key: 'add'
        },
        {
          key: 'remove'
        }
      ],
      criteriaListIndex: null,
      ruleMpngId: null,
      ruleAppCriteriaList: [],
      ruleAppCriteriaFields: [
        {
          key: 'criteria_name'
        },
        {
          key: 'operator'
        },
        {
          key: 'add'
        },
        {
          key: 'remove'
        }
      ]
    };
  },
  validations: {
    ruleForm: {
      rule_name: {
        required
      }
    },
    selectedLegalEntity: {
      value: {
        required
      }
    }
  },
  mounted() {
    this.getLegalEntity();
    this.eventBus.$on('groupDetail', groupDetail => {
      this.ruleDetailFeilds[1].class = '';
      this.getRuleDetail(groupDetail.rule_hdr_id);
    });
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode) {
          this.addEditRule();
        }
        if (actionName === 'save' && this.appCriteriaModal) {
          this.addEditRuleAppCriteria();
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
        if (actionName === 'upload') {

          this.eventBus.$emit('commonUpload', { id: this.ruleForm.rule_hdr_id});

        }
      }
    });
  },
  methods: {
    addEditRule() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const ruleDetail = this.ruleForm.rule_details.map(detatil => {
          return {
            rule_mpng_id: detatil.rule_mpng_id,
            master_rule_hdr_id: detatil.master_rule_hdr_id
          };
        });
        const payload = {
          le_id: this.selectedLegalEntity.value,
          active: this.ruleForm.active,
          rule_hdr_id: this.ruleForm.rule_hdr_id,
          rule_name: this.ruleForm.rule_name,
          rule_details: ruleDetail
        };
        this.loader = true;
        this.$store
          .dispatch('workflow/addEditRules', payload)
          .then(response => {
            this.loader = false;
            this.showAlert = true;
            if (response.status === 200) {
              this.$emit('updateList');
              this.editMode = false;
              this.isSuccess = true;
              this.respMessage = response.data.message;
            } else {
              this.isSuccess = false;
              this.respMessage = response.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    addEditRuleAppCriteria() {
      const details = this.ruleAppCriteriaList.map(criteria => {
        return {
          application_criteria_hdr_id: criteria.application_criteria_hdr_id,
          operator: criteria.operator,
          rule_criteria_mpng_id: criteria.rule_criteria_mpng_id
        };
      });
      const payload = {
        application_criteria_details: details,
        rule_mpng_id: this.ruleMpngId
      };
      this.loader = true;
      this.$store
        .dispatch('workflow/addEditRuleAppCriteria', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.appCriteriaModal = false;
            this.editMode = false;
            this.isSuccess = true;
            this.respMessage = response.data.message;
          } else {
            this.isSuccess = false;
            this.respMessage = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getRuleDetail(groupHdrId) {
      this.loader = true;
      this.$store
        .dispatch('workflow/getRulesDetail', groupHdrId)
        .then(response => {
          this.editMode = false;
          this.loader = false;
          if (response.status === 200) {
            this.ruleForm = response.data.data;
            this.selectedLegalEntity.value = this.ruleForm.le_id;
            if (this.ruleForm.rule_details.length === 0) {
              this.ruleForm.rule_details = [
                {
                  master_rule_hdr_id: null,
                  rule_mpng_id: null,
                  master_rule_name: null
                }
              ];
            }
            this.selectedLegalEntity.text = this.ruleForm.le_name;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getLegalEntity() {
      const payload = store.state.shared.responsibilityId;
      this.$store.dispatch('receipt/getOrganizationLov', payload).then(response => {
        if (response.status === 200) {
          const results = response.data.data;
          const leagalEntity = results.map(type => {
            return {
              value: type.org_id,
              text: type.org_name
            };
          });
          this.legalEntityList = leagalEntity;
        }
      });
    },
    addNewRow() {
      if (this.editMode) {
        this.ruleForm.rule_details.push({
          master_rule_hdr_id: null,
          rule_mpng_id: null,
          master_rule_name: null
        });
      }
    },
    removeRow(index) {
      this.ruleForm.rule_details.splice(index, 1);
    },
    hideMasterRuleModal() {
      this.showMasterRuleModal = false;
    },
    openMasterRuleModal(index) {
      this.ruleIndex = index;
      this.showMasterRuleModal = true;
    },
    selectedRule(item) {
      this.ruleForm.rule_details[this.ruleIndex].master_rule_name =
        item.rule_name;
      this.ruleForm.rule_details[this.ruleIndex].master_rule_hdr_id =
        item.master_rule_hdr_id;
      this.showMasterRuleModal = false;
    },
    showAppCriteriaModal(ruleMpngId) {
      this.ruleMpngId = ruleMpngId;
      this.appCriteriaModal = true;
      this.loader = true;
      this.ruleAppCriteriaList = [];
      this.$store
        .dispatch('workflow/getRuleAppCriteriaByRuleId', ruleMpngId)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            if (response.data.data.length > 0) {
              this.ruleAppCriteriaList = response.data.data;
            } else {
              this.ruleAppCriteriaList.push({
                application_criteria_hdr_id: null,
                rule_criteria_mpng_id: null,
                rule_mpng_id: this.ruleMpngId,
                operator: null,
                criteria_name: null
              });
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    hideAppCriteriaModal() {
      this.appCriteriaModal = false;
    },
    addCriteriaNewRow() {
      this.ruleAppCriteriaList.push({
        application_criteria_hdr_id: null,
        rule_criteria_mpng_id: null,
        rule_mpng_id: this.ruleMpngId,
        operator: null,
        criteria_name: null
      });
    },
    removeCriteriaRow(index) {
      this.ruleAppCriteriaList.splice(index, 1);
    },
    openMainAppCriteModal(index) {
      this.criteriaListIndex = index;
      this.mainAppCreriaModal = true;
    },
    selectedAppCriteria(item) {
      this.ruleAppCriteriaList[this.criteriaListIndex].criteria_name =
        item.criteria_name;
      this.ruleAppCriteriaList[
        this.criteriaListIndex
      ].application_criteria_hdr_id = item.application_criteria_hdr_id;
      this.mainAppCreriaModal = false;
    },
    hideMainAppCriteriaModal() {
      this.mainAppCreriaModal = false;
    },
    openValueSetModal(vsetCode, index) {
      this.criteriaListIndex = index;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      this.showValueSetModal = false;
      this.ruleAppCriteriaList[this.criteriaListIndex].operator =
        item.value_code;
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    rowSelected() {}
  },
  beforeDestroy() {
    clearTimeout(this.setTimeVsetCode);
    this.eventBus.$off('groupDetail');
    this.unsubscribe();
  }
};
