import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import workflowEvent from '../../workFlow/workflowEvent';
import NotificationDetails from './notificationDetails';
export default {
  name: 'noficationSummary',
  components: { workflowEvent, NotificationDetails },
  data() {
    return {
      eventId: null,
      showDetailsModal: false,
      eventName: null,
      showEventsModal: false,
      perPage: commonHelper.perPageRecord,
      totalRows: null,
      currentPage: 1,
      loader: false,
      searchCriteria: null,
      notificationData: [],
      notificationFields: [
        {
          key: 'event_name',
          label: 'Event Name'
        },
        {
          key: 'event_id',
          class: 'd-none'
        },
        {
          key: 'INPROCESS',
          label: 'INPROCESS'
        },
        {
          key: 'NOTREQUIRED',
          label: 'NOTREQUIRED'
        },
        {
          key: 'HOLD',
          label: 'HOLD'
        },
        {
          key: 'RESUBMIT',
          label: 'RESUBMIT'
        },
        {
          key: 'CONSULT',
          label: 'CONSULT'
        },
        {
          key: 'DRAFT'
        },
        {
          key: 'AUTO-REJECTED'
        }
      ],
      tempData: null
    };
  },
  mounted() {
    this.getNotificationList();
  },
  methods: {
    selectedPORow(item) {
      if (item.event_id) {
        const prompt = confirm('Are you sure you want to set default event');
        if (prompt) {
          this.addDefaultEvent(item.event_id);
        } else {
          this.tempData.map((ele, index) => {
            if (ele.default) {
              this.notificationData[index].default = true;
            } else {
              this.notificationData[index].default = false;
            }
          });
        }
      }
    },
    addDefaultEvent(event_id) {
      const payload = { event_id: event_id };
      this.loader = true;
      this.$store
        .dispatch('workflow/addDefaultEvent', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            alert('Default Event Set Successfully');
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },

    getNotificationList() {
      const payload = {
        event_name: this.eventName
      };
      this.loader = true;
      this.$store
        .dispatch('workflow/searchNotification', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200 && response.data.data.length > 0) {
            const finalArray = response.data.data.map(data => {
              return {
                event_name: data.event_name,
                event_id: data.event_id,
                default: data.default,
                ...this.eventStatus(data.notification_status)
              };
            });
            this.notificationFields = [];
            for (let i = 0; i < finalArray.length; i++) {
              for (let key in finalArray[i]) {
                this.notificationFields.push({
                  key: key,
                  label:
                    key == 'default'
                      ? 'Default Event'
                      : key == 'event_name'
                      ? 'Event Name'
                      : key,
                  class: !key.match('_id') ? '' : 'd-none'
                });
              }
            }
            const customOrder = [
              'default',
              'event_name',
              'event_id',
              'INPROCESS'
            ];
            this.notificationFields = this.filterAndSortObjects(
              this.notificationFields,
              customOrder
            );
            this.notificationData = finalArray;
            this.tempData = JSON.parse(JSON.stringify(finalArray));
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    filterAndSortObjects(array, order) {
      const uniqueKeys = new Set();
      const filteredArray = array.filter(obj => {
        if (!uniqueKeys.has(obj.key)) {
          uniqueKeys.add(obj.key);
          return true;
        }
        return false;
      });
      return filteredArray.sort(
        (a, b) => order.indexOf(a.key) - order.indexOf(b.key)
      );
    },
    eventStatus(data) {
      const obj = {};
      data.forEach(status => {
        obj[status.current_status] = status.notification_count;
      });
      return obj;
    },
    summaryDetail(status, eventId) {
      this.showDetailsModal = true;
      const data = {
        status: status,
        eventId: eventId
      };
      this.timeOut = setTimeout(() => {
        this.eventBus.$emit('summaryDetail', data);
      }, 0);
    },
    selectedworkflowEvent(item) {
      this.eventName = item.event_name;
      this.showEventsModal = false;
    },
    showHideEventModal(flag) {
      this.showEventsModal = flag;
    },
    showHideDetailModal(flag) {
      this.showDetailsModal = flag;
    }
  }
};
