import commonHelper from '@/app/utility/common.helper.utility';
import addRule from './addRule';
import { ModelSelect } from 'vue-search-select';
import store from '../../../../store';
export default {
  name: 'workFlowGroup',
  components: { addRule, ModelSelect },
  props: { showRuleModal: Boolean },
  watch: {
    currentPage: function() {
      this.getRuleList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getRuleList();
    }
  },
  data() {
    return {
      setTimeVsetCode: null,
      showValueSetModal: false,
      active: true,
      timeOut: null,
      showAddGroup: false,
      unsubscribe: null,
      ruleName: null,
      payload: {},
      loader: false,
      pageOptions: commonHelper.getPageOption(),
      perPage: commonHelper.perPageRecord,
      valueSetName: null,
      currentPage: 1,
      totalRows: null,
      legalEntityList: [],
      selectedLegalEntity: {
        value: null,
        text: null
      },
      ruleList: [],
      ruleFeilds: [
        {
          key: 'rule_name'
        },
        {
          key: 'le_name',
          label: 'Legal Entity'
        },
        {
          key: 'active'
        }
      ]
    };
  },
  mounted() {
    this.getLegalEntity();
    this.getRuleList();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.showAddGroup = true;
        }
        if (actionName === 'download') {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'workflow/getRulesList',
            'workflow-rule',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    getRuleList() {
      this.loader = true;
      this.payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        rule_name: this.ruleName,
        active: this.active,
        le_id: this.selectedLegalEntity.value
      };
      this.$store
        .dispatch('workflow/getRulesList', this.payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.ruleList = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getLegalEntity() {
      const payload = store.state.shared.responsibilityId;
      this.$store
        .dispatch('receipt/getOrganizationLov', payload)
        .then(response => {
          if (response.status === 200) {
            const results = response.data.data;
            const leagalEntity = results.map(type => {
              return {
                value: type.org_id,
                text: type.org_name
              };
            });
            this.legalEntityList = leagalEntity;
          }
        });
    },
    clear() {
      this.ruleName = null;
      this.active = false;
      this.selectedLegalEntity = {
        value: null,
        text: null
      };
      this.getRuleList();
    },
    hideGroupModal() {
      this.showAddGroup = false;
    },
    rowSelected(item) {
      if (this.showRuleModal) {
        this.$emit('selectedRules', item);
      } else {
        this.showAddGroup = true;
        this.timeOut = setTimeout(() => {
          this.eventBus.$emit('groupDetail', item);
        }, 0);
      }
    },
    updateList() {
      this.getRuleList();
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    openValueSetModal(vsetCode) {
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      this.selectedLegalEntity.value = item.org_id;
      this.selectedLegalEntity.text = item.org_name;
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === 'LEGAL_ENTITY_LIST') {
        this.selectedLegalEntity.value = null;
        this.selectedLegalEntity.text = null;
      }
    }
  },
  beforeDestroy() {
    clearTimeout(this.timeOut);
    this.unsubscribe();
  }
};
